import { useOrgId } from '@/components/Providers/auth/OrgIdProvider';
import { IconButtonProps, PaperProps, PopperProps, TextField, styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useMemo } from 'react';
import { useUserPermissionsAtom } from '../hooks/Permissions';

export const OverflowAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '.MuiFormControl-root': {
    '.MuiInputBase-root': {
      fontSize: '10px',
      padding: '2px',
    },
  },
}));

interface OrgSelectorProps {
  componentsProps?: {
    clearIndicator?: Partial<IconButtonProps>;
    paper?: PaperProps;
    popper?: Partial<PopperProps>;
    popupIndicator?: Partial<IconButtonProps>;
  };
}

export const OrgSelector = ({ componentsProps = { popper: { style: { width: '700px' } } } }: OrgSelectorProps) => {
  const [permissions] = useUserPermissionsAtom();
  const { orgId, setOrgId } = useOrgId();

  const onOrgClick = useCallback(
    (orgId: number) => {
      setOrgId(orgId);
      window.location.reload();
    },
    [setOrgId]
  );

  const allOptions = useMemo(
    () =>
      (permissions?.memberships ?? [])
        .sort((a, b) => a.orgName.localeCompare(b.orgName))
        .map((m) => ({ label: `${m.orgName} (${m.primaryUserName}) #${m.orgId}`, id: m.orgId })),
    [permissions?.memberships]
  );
  const selectedValue = useMemo(() => allOptions?.find((o) => o.id === orgId), [allOptions, orgId]);

  if (!permissions?.memberships) {
    return <div>Loading...</div>;
  }

  return (
    <OverflowAutocomplete
      options={allOptions}
      renderInput={(params) => <TextField {...params} />}
      disableClearable
      blurOnSelect
      clearOnBlur
      selectOnFocus
      componentsProps={componentsProps}
      forcePopupIcon={false}
      value={selectedValue ?? null}
      onChange={(e, newValue) => {
        onOrgClick((newValue as { id: number }).id);
      }}
      size="small"
    />
  );
};
