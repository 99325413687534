import FenceIcon from '@mui/icons-material/Fence';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { AccountBalance, BarChartOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import SettingsIcon from '@mui/icons-material/Settings';

export const NavPens = { label: 'Pens', href: '/dashboard/pens', Icon: FenceIcon };
export const NavLots = { label: 'Lots', href: '/dashboard/lots', Icon: LocalOfferOutlinedIcon };
export const NavInventory = { label: 'Inventory', href: '/dashboard/inventory', Icon: WarehouseOutlinedIcon };
export const NavMedicalInventory = { label: 'Health', href: '/dashboard/medical-inventory', Icon: MedicalServicesIcon };
export const NavFeeding = { label: 'Feeding', href: '/dashboard/feeding', Icon: RestaurantIcon };
export const NavBilling = { label: 'Billing', href: '/dashboard/billing', Icon: AccountBalance };
export const NavReports = { label: 'Reports', href: '/dashboard/reports', Icon: BarChartOutlined };
export const NavMore = { label: 'More', href: '/dashboard/more', Icon: MenuIcon };
export const NavDesktopMode = { label: 'Desktop', href: null, Icon: FullscreenIcon };
export const NavSwitchYard = { label: 'Switch Yard', href: null, Icon: SyncAltIcon };
export const NavSettings = { label: 'Settings', href: '/dashboard/settings', Icon: SettingsIcon };
export const FOOTER_NAV_ITEMS = [NavSettings];
export const MORE_NAV_ITEMS = [NavMedicalInventory, NavReports, NavSettings];

export const FEEDER_NAV_ITEMS = [NavFeeding];
export const USER_NAV_ITEMS = [NavPens, NavLots, NavInventory, NavMedicalInventory, NavFeeding];
export const DEFAULT_NAV_ITEMS = [
  NavPens,
  NavLots,
  NavInventory,
  NavMedicalInventory,
  NavFeeding,
  NavBilling,
  NavReports,
];
